<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="2">
        <date-picker
          name="dateFilterMenu"
          :placeholderItem="$t('Search by date')"
          :dateOptions="{
            dateClearable: true,
            dateDisabled: false,
            dataFieldValidation: '',
            dateRules: '',
          }"
          :dateValue="''"
          @clearDate="changeFilter()"
          @changeDatePicker="changeFilter($event)"
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="customerId"
          :items="getSearchCustomers"
          :loading="searchingCustomerFilter"
          :search-input.sync="searchCustomerWatchFilter"
          item-text="Name"
          item-value="Id"
          clearable
          hide-no-data
          :placeholder="$t('Search a customer')"
          @change="changeFilter()"
          return-object
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-text-field
          v-model="search"
          single-line
          append-icon="mdi-magnify"
          :label="$t('Search')"
          hide-details
          clearable
          @keydown.enter="searchItem()"
        >
          <v-icon slot="append" @click="searchItem()"> mdi-magnify </v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items.sync="getConfirmedBaskets"
      :items-per-page.sync="itemsPerPage"
      :options.sync="options"
      class="elevation-1 ma-3 pa-4"
      hide-default-footer
      :loading="isLoading"
      :no-data-text="$t('No data available')"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          @click="setAddToShoppingCartDialog(item)"
          elevation="2"
          fab
          x-small
          :loading="addToShoppingCartLoading"
        >
          <v-icon>mdi-cart-plus</v-icon>
        </v-btn>
      </template>

      <template v-slot:footer>
        <v-row class="mx-2 py-2" align="center" justify="center">
          <v-spacer></v-spacer>

          <span class="mr-4 grey--text">
            <small
              >{{ $t("Page") }} {{ getPage }} {{ $t("out of") }}
              {{ getAmountOfPages }}</small
            >
          </span>
          <v-btn
            :disabled="hasPrevPage"
            fab
            x-small
            class="mr-1"
            @click="formerPage()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            :disabled="hasNextPage"
            fab
            x-small
            class="ml-1"
            @click="nextPage()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
    <v-dialog v-model="addToShoppingCartDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("Add items to shopping cart") }}</span>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="6">
              <v-autocomplete
                v-model="addToShoppingCartUser"
                :items="getSearchUsers"
                :loading="searchingUsers"
                :search-input.sync="searchUsersWatch"
                item-text="FirstName"
                item-value="Id"
                clearable
                hide-no-data
                :placeholder="$t('Search a customer')"
                @change="searchUsersWatch = ''"
                return-object
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="addToShoppingCartDialog = false">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="addConfirmedBasketToBasket(addToShoppingCartItem)"
            :loading="isLoading"
          >
            {{ $t("Add to Shopping cart") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <errorHandeling
      v-if="error.length > 0"
      :snackbarText="error"
      snackbarColor="error"
      snackbarTimout="-1"
      snackbarIcon="mdi-warning"
      @clearError="error = ''"
    />
  </div>
</template>

<script>
import helpers from "../../helpers";
import request from "../../request";
export default {
  data() {
    return {
      confirmedBaskets: [],
      page: 1,
      amountOfPages: 1,
      count: 20,
      start: 0,
      itemsPerPage: 20,
      customers: [],
      search: "",
      options: {},
      loading: false,
      addToShoppingCartDialog: false,
      addToShoppingCartItem: null,
      addToShoppingCartUser: null,
      addToShoppingCartLoading: false,
      searchingContent: false,
      searchResults: [],
      selectedSearchResult: null,
      searchUsers: [],
      searchingUsers: false,
      searchUsersWatch: "",
      searchCustomers: [],
      searchingCustomerFilter: false,
      searchCustomerWatchFilter: "",
      timerSearchCustomer: null,
      isLoading: false,
      error: "",
      date: null,
      customerId: null,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("Created"),
          align: "start",
          sortable: true,
          value: "BasketCreated",
        },
        {
          text: this.$t("Made by"),
          sortable: true,
          value: "UserName",
        },
        {
          text: this.$t("Customer"),
          sortable: true,
          value: "CustomerName",
        },
        {
          text: this.$t("Number of articles"),
          sortable: false,
          value: "TotalAmount",
        },
        {
          text: this.$t("Reference"),
          sortable: true,
          value: "Reference",
        },
        {
          text: this.$t("Remark"),
          sortable: true,
          value: "Remark",
        },

        {
          text: this.$t("Add"),
          value: "action",
          sortable: false,
        },
      ];
    },
    getPage: {
      get() {
        return this.page;
      },
      set(newItem) {
        this.page = newItem;
      },
    },
    hasPrevPage() {
      if (this.getPage == 1) {
        return true;
      }
      return false;
    },
    hasNextPage() {
      if (this.getPage < this.amountOfPages) {
        return false;
      }
      return true;
    },
    getAmountOfPages: {
      get() {
        if (this.amountOfPages == 0) {
          return 1;
        }
        return this.amountOfPages;
      },
      set(newItem) {
        this.amountOfPages = newItem;
      },
    },
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
    getSearchCustomers() {
      if (!this.searchCustomers) {
        return [];
      }
      this.searchCustomers.forEach((element) => {
        element.Name = element.Name + " (" + element.Code + ")";
      });
      return this.searchCustomers;
    },
    getSearchUsers() {
      return this.searchUsers;
    },
    getConfirmedBaskets() {
      const items = this.confirmedBaskets;
      items.forEach((element) => {
        element.BasketCreated = helpers.changeTimeToReadeble(
          element.BasketCreated
        );
      });
      return items;
    },
  },
  watch: {
    search: function (params) {
      params ? params : (params = "");
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.search = params;
        this.getConfirmedBasketsCount({
          search: this.search,
          date: this.date,
          customerId: this.customerId,
        });
        this.getConfirmedBasketsCall({
          start: this.start,
          count: this.itemsPerPage,
          search: params,
          date: this.date,
          customerId: this.customerId,
          sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
          sortDescending:
            this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
        });
      }, 2000);
    },
    options: {
      handler() {
        this.getConfirmedBasketsCall({
          start: this.start,
          count: this.count,
          search: this.search ? this.search : "",
          date: this.date,
          customerId: this.customerId,
          sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
          sortDescending:
            this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
        });
      },
      deep: true,
    },
    searchUsersWatch: function (searchValue) {
      if (searchValue == null) {
        return;
      }
      clearTimeout(this.timerSearchCustomer);
      this.timerSearchCustomer = setTimeout(() => {
        if (searchValue != "") {
          this.searchUsersPersons(searchValue);
        }
      }, 200);
    },

    searchCustomerWatchFilter: function (searchValue) {
      if (searchValue == null) {
        return;
      }
      clearTimeout(this.timerSearchCustomer);
      this.timerSearchCustomer = setTimeout(() => {
        if (searchValue != "") {
          this.searchCustomerFilter(searchValue);
        }
      }, 200);
    },
  },
  created() {
    this.getConfirmedBasketsCount({
      search: this.search,
      date: this.date,
      customerId: this.customerId,
    });
    this.getConfirmedBasketsCall({
      start: this.start,
      count: this.itemsPerPage,
      search: this.search ? this.search : "",
      date: this.date,
      customerId: this.customerId,
      sort: false,
      sortDescending: false,
    });
  },
  methods: {
    changeFilter(event) {
      this.date = event;
      this.getConfirmedBasketsCount({
        search: this.search,
        date: this.date,
        customerId: this.customerId,
      });
      this.getConfirmedBasketsCall({
        start: this.start,
        count: this.itemsPerPage,
        search: this.search,
        date: this.date,
        customerId: this.customerId,
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
        sortDescending:
          this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
      });
    },
    searchItem() {
      clearTimeout(this.timer);
      this.getConfirmedBasketsCount({
        search: this.search,
        date: this.date,
        customerId: this.customerId,
      });
      this.getConfirmedBasketsCall({
        start: this.start,
        count: this.itemsPerPage,
        search: this.search,
        date: this.date,
        customerId: this.customerId,
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
        sortDescending:
          this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
      });
    },
    addConfirmedBasketToBasket(item) {
      this.isLoading = true;
      request.post(
        "/api/kdesign/add-confirmed-basket-to-basket?confirmedBasketId=" +
          item.Id +
          "&basketUserId=" +
          this.addToShoppingCartUser.Id,
        null,
        (res) => {
          if (!res.success) {
            this.error = res.message;
            this.addToShoppingCartDialog = false;
            return;
          }
          this.addToShoppingCartDialog = false;
          this.page = 1;
          this.getConfirmedBasketsCount({
            search: this.search,
            date: this.date,
            customerId: this.customerId,
          });
          this.getConfirmedBasketsCall({
            start: 0,
            count: this.itemsPerPage,
            search: this.search ? this.search : "",
            date: this.date,
            customerId: this.customerId,
            sort: false,
            sortDescending: false,
          });
        }
      );
    },
    searchCustomerFilter(search) {
      if (this.searchingCustomerFilter) return;
      this.searchingCustomerFilter = true;
      request.get("/api/kdesign/customers?search=" + search, null, (res) => {
        this.searchCustomers = res;
        this.searchingCustomerFilter = false;
      });
    },
    searchUsersPersons(search) {
      if (this.searchingUsers) return;
      this.searchingUsers = true;
      request.get("/api/kdesign/users?search=" + search, null, (res) => {
        this.searchUsers = res;
        this.searchingUsers = false;
      });
    },
    setAddToShoppingCartDialog(item) {
      this.addToShoppingCartItem = item;

      this.addToShoppingCartUser = {
        FirstName: item["UserName"] ? item["UserName"] : item["FirstName"],
        Id: item["BasketUserId"] ? item["BasketUserId"] : item["Id"],
      };
      this.searchUsers.push(this.addToShoppingCartUser);
      this.addToShoppingCartDialog = true;
    },
    getConfirmedBasketsCount(options) {
      let baseUrl =
        "/api/kdesign/confirmed-baskets-count?search=" + options.search;

      if (options.date) {
        baseUrl = baseUrl + "&date=" + options.date;
      }
      if (options.customerId) {
        baseUrl = baseUrl + "&customerId=" + options.customerId.Id;
      }
      request.get(baseUrl, null, (res) => {
        this.amountOfPages = Math.ceil(res.item / this.itemsPerPage);
      });
    },
    getConfirmedBasketsCall(options) {
      this.isLoading = true;
      let baseUrl =
        "/api/kdesign/confirmed-baskets?start=" +
        options.start +
        "&count=" +
        options.count;
      if (options.search) {
        baseUrl = baseUrl + "&search=" + options.search;
      }
      if (options.date) {
        baseUrl = baseUrl + "&date=" + options.date;
      }
      if (options.customerId) {
        baseUrl = baseUrl + "&customerId=" + options.customerId.Id;
      }
      request.get(baseUrl, null, (res) => {
        this.confirmedBaskets = res;
        this.isLoading = false;
      });
    },
    getCustomer(customerId) {
      if (!this.customers.length) {
        return;
      }
      const customer = this.customers.find((el) => el.Id == customerId);
      return customer ? customer.Name : "";
    },

    nextPage() {
      this.page++;
      this.start = this.start + this.itemsPerPage;
      this.getConfirmedBasketsCall({
        start: this.start,
        count: this.itemsPerPage,
        search: this.search ? this.search : "",
        date: this.date,
        customerId: this.customerId,
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
        sortDescending:
          this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
      });
    },

    formerPage() {
      this.page--;
      this.start = this.start - this.itemsPerPage;
      this.getConfirmedBasketsCall({
        start: this.start,
        count: this.itemsPerPage,
        search: this.search ? this.search : "",
        date: this.date,
        customerId: this.customerId,
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
        sortDescending:
          this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
      });
    },
  },
};
</script>

<style>
</style>

